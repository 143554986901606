import React from 'react'
import styled, { css } from 'styled-components'

const activeColor = '#043e86'
const nonActiveColor = '#829fc3'

const getColor = (isActive) => isActive ? activeColor : nonActiveColor

const SortUp = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="5"
    viewBox="0 0 10 5"
  >
    <g>
      <g>
        <path fill={getColor(isActive)} d="M0 5l5-5 5 5z" />
      </g>
    </g>
  </svg>
)

const SortDown = ({ isActive }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="5"
    viewBox="0 0 10 5"
  >
    <g>
      <g>
        <path fill={getColor(isActive)} d="M0 0l5 5 5-5z" />
      </g>
    </g>
  </svg>
)

const Sort = ({ down = false, ...props }) => {
  const SortComponent = down ? SortDown : SortUp
  return <SortComponent {...props} />
}

export default Sort
